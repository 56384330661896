<template>
  <div>
    <div class="card">
      <div class="card-body">
        <h5 class="mb-4">
          <strong>Ubah Password Farmasi</strong>
        </h5>
        <a-form :form="form"  @submit="handleSubmit">
          <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Password Lama">
            <a-input-password
              :disabled="submitting"
              placeholder="Password Lama"
              v-decorator="['oldPassword', {rules: [{ required: true, message: 'Password lama harus diisi!' }]}]"
            />
          </a-form-item>
          <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Password Baru">
            <a-input-password
              :disabled="submitting"
              placeholder="Password Baru"
              v-decorator="['newPassword', {rules: [{ required: true, message: 'Password Baru harus diisi' }]}]"
            />
          </a-form-item>
          <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Konfirmasi Password Baru: ">
            <a-input-password
              :disabled="submitting"
              placeholder="Konfirmasi Password Baru"
              v-decorator="['confirmPassword', {rules: [{ required: true, message: 'Konfirmasi password harus diisi' }]}]"
            />
          </a-form-item>
          <!-- <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Password">
            <a-button class="btn btn-primary px-5 m-1" :disabled="submitting" @click="(e)=>{e.preventDefault(); setModalVisible2(true)}">Reset Password</a-button>
          </a-form-item> -->
          <button type="submit" class="btn btn-success px-5 mr-2" :disabled="submitting">Simpan Data</button>
          <button class="btn btn-secondary px-5" :disabled="submitting" @click="handleCancel">Kembali</button>
        </a-form>
      </div>
    </div>
  </div>
</template>
<script>

import router from '@/router'
import { getById, deleteById } from '@/services/axios/api/verifikator'
import { getAll } from '@/services/axios/api/city'
import { resetPassword, changePassword } from '@/services/axios/api/auth'
import { notification } from 'ant-design-vue'

const labelCol = {
  xs: { span: 24 },
  sm: { span: 4 },
}
const wrapperCol = {
  xs: { span: 24 },
  sm: { span: 16 },
}
export default {
  data: function () {
    return {
      form: this.$form.createForm(this),
      labelCol,
      wrapperCol,
      cityList: [],
      isLoading: true,
      submitting: false,
      modalVisible: false,
      modalVisible2: false,
    }
  },
  created() {
  },
  methods: {
    async getVerifikatorById() {
      // init form field
      this.form.getFieldDecorator('fullname', { initialValue: '' })
      this.form.getFieldDecorator('email', { initialValue: '' })
      this.form.getFieldDecorator('role', { initialValue: 'verificator' })
      // get data
      const res = await getById(this.$route.params.id)

      // set loading false
      this.isLoading = false

      if (res) {
        // set form data
        this.form.setFieldsValue({ fullname: res.fullname })
        this.form.setFieldsValue({ email: res.email })
      }
    },
    async getCityList() {
      const res = await getAll()
      this.cityList = res
    },
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields(async (err, values) => {
        if (!err) {
          this.submitting = true
          console.log('Received values of form: ', values)
          if (values.newPassword === values.confirmPassword) {
            const { oldPassword, newPassword } = values
            try {
              const res = await changePassword(this.$route.params.id, { oldPassword, newPassword, role: 'pharmacy' })
              if (res && res.data) {
                notification.success({
                  message: 'Sukses',
                  description: 'Data password berhasil diubah',
                  duration: 5,
                })
                router.push('/farmasi')
              }
              this.submitting = false
            } catch (e) {
              this.submitting = false
            }
          } else {
            this.$notification.warning({
              message: 'Gagal',
              description: 'Konfirmasi password tidak sesuai dengan password baru',
              duration: 5,
            })
          }
        }
      })
    },
    async handleDelete() {
      const res = await deleteById(this.$route.params.id)
      if (res && res.data) {
        notification.success({
          message: 'Sukses',
          description: 'Data berhasil dihapus',
          duration: 5,
        })
        router.push('/verifikator')
      } else {
        notification.warning({
          message: 'Gagal',
          description: 'Data gagal dihapus',
          duration: 5,
        })
      }
      this.submitting = false
      this.modalVisible = false
    },
    handleCancel(e) {
      e.preventDefault()
      router.push('/farmasi')
    },
    async handleResetPassword() {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          this.submitting = true
          console.log('Received values of form: ', values)
          try {
            const res = await resetPassword(values, 'verificator')
            if (res && res.data) {
              notification.success({
                message: 'Sukses',
                description: 'Password berhasil direset, silakan cek email terdaftar',
                duration: 5,
              })
            } else {
              notification.warning({
                message: 'Gagal',
                description: 'Password gagal direset',
                duration: 5,
              })
            }
            this.submitting = false
          } catch (e) {
            this.submitting = false
          }
        }
      })
    },
  },
}
</script>
